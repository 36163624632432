import {
    saveTodos,
    getTodos,
    removeTodo,
    toggleTodo,
} from './todos';
import {
    getFilters,
} from './filters';


const renderTodos = () => {
    const todosEl = document.querySelector('#todos')
    const {
        searchText,
        hideCompleted
    } = getFilters()
    const filteredTodos = getTodos().filter((todo) => {
        const searchTextMatch = todo.todoText.toLowerCase().includes(searchText.toLowerCase())
        const hideCompletedMatch = !hideCompleted || !todo.completed

        return searchTextMatch && hideCompletedMatch
    })

    const incompleteTodos = filteredTodos.filter((todo) => !todo.completed)

    todosEl.innerHTML = ''
    todosEl.appendChild(generateSummaryDOM(incompleteTodos))

    if (filteredTodos.length > 0) {
        filteredTodos.forEach((todo) => {
            todosEl.appendChild(generateTodoDOM(todo))
        })
    } else {
        const emptyMessage = document.createElement('p')
        emptyMessage.textContent = 'No to-dos to show'
        emptyMessage.classList.add('empty-message')
        todosEl.appendChild(emptyMessage)
    }
}

const generateTodoDOM = (todo) => {
    const todoEl = document.createElement('label')
    const containerEl = document.createElement('div')
    const checkbox = document.createElement('input')
    const todoText = document.createElement('span')
    const removeButton = document.createElement('button')

    // Setup todo checkbox
    checkbox.setAttribute('type', 'checkbox')
    checkbox.checked = todo.completed
    containerEl.appendChild(checkbox)
    checkbox.addEventListener('change', () => {
        toggleTodo(todo.id)
        saveTodos(todos)
        renderTodos(todos, getFilters())
    })

    // Setup the todo text
    todoText.textContent = todo.todoText
    containerEl.appendChild(todoText)
    todoEl.appendChild(containerEl)

    // Setup container
    todoEl.classList.add('list-item')
    containerEl.classList.add('list-item__container')

    // Setup the remove button
    removeButton.textContent = 'remove'
    removeButton.classList.add('button', 'button--text')
    todoEl.appendChild(removeButton)
    removeButton.addEventListener('click', () => {
        removeTodo(todo.id)
        saveTodos(todos)
        renderTodos(todos, getFilters())
    })

    return todoEl
}

const generateSummaryDOM = (incompleteTodos) => {
    const summary = document.createElement('h2')
    const plural = incompleteTodos.length === 1 ? '' : 's'
    summary.textContent = `You have ${incompleteTodos.length} todo${plural} left`
    summary.classList.add('list-title')
    return summary
}

export {
    renderTodos,
    generateTodoDOM,
    generateSummaryDOM
}